import { useAuth0 } from '@auth0/auth0-react';
import styled from '@emotion/styled';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DeliveryCancelReason } from '@coco/types/deliveries';
import { CocoButtonV2, Colors, Typography } from '@coco/ui-web';

import { EVENTS } from 'src/@types';
import { useCancelDelivery } from 'src/hooks';
import { CANCEL_DELIVERY_KEYS, LOC_NS, ORDERS_KEYS } from 'src/i18n/types';
import { trackSegment } from 'src/lib/segment';
import { useDeliveryStore } from 'src/stores/delivery';
import { useOrderStore } from 'src/stores/orders';

const ContentContainer = styled.div({
  width: '470px',
  backgroundColor: Colors.grey100,
});

const HeaderContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  position: 'relative',
  padding: '24px 36px',
  borderBottom: `1px solid ${Colors.grey300}`,
});

const HeaderCopy = styled.div({
  color: Colors.black,
});

const BodyContainer = styled.div({
  padding: '24px 36px',
});

const BodyHeader = styled.div({
  color: Colors.black,
});

const Disclaimer = styled.div({
  marginTop: '12px',
  marginBottom: '24px',
  color: Colors.red500,
  textAlign: 'center',
});

export const CancelDeliveryModal = () => {
  const { t } = useTranslation(LOC_NS.CANCEL_DELIVERY);
  const { t: ordersT } = useTranslation(LOC_NS.ORDERS);
  const { user } = useAuth0();
  const { selectedDelivery, setSelectedDelivery } = useDeliveryStore();
  const { setSelfCanceledDeliveryId } = useOrderStore();

  const [cancelReason, setCancelReason] = useState<DeliveryCancelReason | null>(null);

  const cancelDeliveryMutation = useCancelDelivery({
    deliveryId: selectedDelivery?.id ?? '',
    reason: cancelReason ?? DeliveryCancelReason.MerchantRequestedOther,
  });

  const handleCancelDelivery = useCallback(async () => {
    setSelfCanceledDeliveryId(selectedDelivery?.id ?? null);
    await cancelDeliveryMutation.mutateAsync();

    setSelectedDelivery(null);
    setCancelReason(null);

    // When the merchant clicks on Cancel Order button (confirm cancel)
    trackSegment(EVENTS.BUTTON_CLICKED, {
      button_name: 'Confirm Cancel Order',
      order_id: selectedDelivery?.id,
      attempt_status: selectedDelivery?.currentAttemptStatus,
      cancellation_reason: cancelReason,
      auth_id: user?.sub,
      button_clicked_at: Date.now(),
    });

    setSelectedDelivery(null);
    setCancelReason(null);
  }, [
    cancelDeliveryMutation,
    cancelReason,
    selectedDelivery?.currentAttemptStatus,
    selectedDelivery?.id,
    setSelectedDelivery,
    setSelfCanceledDeliveryId,
    user?.sub,
  ]);

  useEffect(() => {
    if (!selectedDelivery) {
      setCancelReason(null);
    }
  }, [selectedDelivery]);

  return (
    <Dialog open={!!selectedDelivery} onClose={() => setSelectedDelivery(null)}>
      <ContentContainer>
        <HeaderContainer>
          <HeaderCopy className={Typography.HEADLINE_SM}>
            {t(CANCEL_DELIVERY_KEYS.CANCEL_DELIVERY, 'Cancel Delivery')}
          </HeaderCopy>
          <CloseIcon
            style={{
              position: 'absolute',
              top: '18px',
              right: '18px',
              height: '30px',
              width: '30px',
              cursor: 'pointer',
              color: Colors.black,
            }}
            onClick={() => setSelectedDelivery(null)}
          />
        </HeaderContainer>
        <BodyContainer>
          <BodyHeader className={Typography.BODY_LG}>
            {t(CANCEL_DELIVERY_KEYS.REASON_FOR_CANCELING, 'Reason for canceling')}
          </BodyHeader>
          <FormControl style={{ marginTop: '12px' }}>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              value={cancelReason}
              onChange={(e: ChangeEvent<HTMLInputElement>, newCancelReason: string) => {
                setCancelReason(newCancelReason as DeliveryCancelReason);
              }}
            >
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedCustomerCancelled}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.CUSTOMER_CANCELED, 'Customer Canceled')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedCustomerPickedUp}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.CUSTOMER_PICKED_UP, 'Customer Picked Up')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedDuplicateOrder}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.DUPLICATE_ORDER, 'Duplicate Order')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedKitchenClosed}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.KITCHEN_CLOSED, 'Kitchen Closed')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedTooBusy}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.RESTAURANT_TOO_BUSY, 'Restaurant Too Busy')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedUnavailableItem}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.UNAVAILABLE_ITEM, 'Unavailable Item')}
              />
              <FormControlLabel
                value={DeliveryCancelReason.MerchantRequestedOther}
                control={<Radio />}
                label={t(CANCEL_DELIVERY_KEYS.OTHER, 'Other')}
              />
            </RadioGroup>
          </FormControl>

          <Disclaimer className={Typography.BODY_MD}>
            {t(
              CANCEL_DELIVERY_KEYS.CANCELS_DELIVERY_ONLY,
              'This cancels delivery only; order must also be canceled on the original platform.'
            )}
          </Disclaimer>

          <CocoButtonV2
            flexible
            hideShadows
            buttonType="primary"
            size="large"
            status={!cancelReason ? 'disabled' : cancelDeliveryMutation.isLoading ? 'loading' : 'enabled'}
            onClick={handleCancelDelivery}
          >
            {t(CANCEL_DELIVERY_KEYS.CANCEL_DELIVERY_FOR, 'Cancel Delivery for')}{' '}
            {selectedDelivery?.customerName ?? ordersT(ORDERS_KEYS.CUSTOMER, 'customer')}
          </CocoButtonV2>
        </BodyContainer>
      </ContentContainer>
    </Dialog>
  );
};
